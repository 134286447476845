import styled from '@emotion/styled';
import Img from 'components/atoms/img';
import { ProjectEvaluateMessageCard } from 'components/molecules/projectEvaluateMessageCard';
import { ProjectEvaluateNumCard } from 'components/molecules/projectEvaluateNumCard';
import { ProjectEvaluateTextCard } from 'components/molecules/projectEvaluateTextCard';
import React from 'react';
import { contentWrapper } from 'style/mixin';
import sizes from 'style/sizes';
import { mq } from 'style/variables';
import { ShifterProjectsJpDataAcfHighlights } from 'types/graphql-types';
import { NonNullableFields } from 'util/types';

export type ProjectEvaluateAreaProps = NonNullableFields<ShifterProjectsJpDataAcfHighlights> & {
  hasNoteIcon?: boolean;
};

const Container = styled.div<ProjectEvaluateAreaProps>`
  opacity: 1;
  display: grid;
  ${mq.onlypc} {
    ${contentWrapper}
    gap: 12px;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-rows: 0.2f 0.32f 0.48f;
    margin-bottom: ${sizes.margin[180]};
  }
  ${mq.onlysp} {
    gap: 4px;
    padding: 4px;
    margin-bottom: ${sizes.margin[100]};
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  ${mq.onlypc} {
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-height: 652px;
  }
  ${mq.onlysp} {
    max-height: 206px;
  }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  ${mq.onlypc} {
    &:nth-of-type(1) {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
    }
    &:nth-of-type(2) {
      grid-row: 1 / 2;
      grid-column: 2 / 4;
      min-height: 287px;
    }
    &:nth-of-type(3) {
      grid-row: 2 / 3;
      grid-column: 2 / 4;
      min-height: 454px;
    }
    &:nth-of-type(4) {
      grid-row: 3 / 4;
      grid-column: 1 / 4;
    }
  }
`;
const MaskImg = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;

export const ProjectEvaluateArea: React.FC<ProjectEvaluateAreaProps> = props => {
  return (
    <Container {...props}>
      {props.contents.map((item, index) => {
        const cardEl = () => {
          switch (item?.type) {
            case 'value':
              return (
                <ProjectEvaluateNumCard
                  number={item.value?.number || ''}
                  unit={item.value?.unit || ''}
                  text={item.value?.text || ''}
                  notes={item.value?.notes || []}
                  {...(props.hasNoteIcon && { hasNoteIcon: { unit: true, text: true } })}
                />
              );
            case 'comment':
              return (
                <ProjectEvaluateTextCard
                  heading={item.comment?.heading || ''}
                  text={item.comment?.text || ''}
                  notes={[]}
                  // 設計で"現状"注釈が入るデータはないためCMS上にもフィールドを作成していない
                  // notes={item.comment?.notes || []}
                />
              );
            case 'message':
              return (
                <ProjectEvaluateMessageCard
                  text={item.message?.text || ''}
                  name={item.message?.name || ''}
                  role={item.message?.role || ''}
                ></ProjectEvaluateMessageCard>
              );
            default:
              return null;
          }
        };
        return <GridItem key={`project-evaluate-${index}`}>{cardEl()}</GridItem>;
      })}
      <GridItem>
        <ImageWrapper>
          {/* <Img filename={props.image} alt={props.image} maskImage={HogehogeClear} /> */}
          <Img filename={props.image} alt={props.image} maskImage={[props.image_mask, props.image_mask]} />
        </ImageWrapper>
      </GridItem>
    </Container>
  );
};
