import styled from '@emotion/styled';
import React from 'react';
import { mq } from 'style/variables';
import firstCommaIcon from 'assets/images/icons/icon-comma-first.svg';
import lastCommaIcon from 'assets/images/icons/icon-comma-last.svg';
import firstCommaIconWhite from 'assets/images/icons/icon-comma-first-white.svg';
import lastCommaIconWhite from 'assets/images/icons/icon-comma-last-white.svg';
import bgImage from 'assets/images/projects/message-bg.png';
import bgImageBlack from 'assets/images/projects/message-bg-bk.png';
import colors from 'style/colors';
import { ShifterProjectsJpDataAcfHighlightsContentsMessage } from 'types/graphql-types';
import { NonNullableFields } from 'util/types';
import { css } from '@emotion/core';
import fonts from 'style/fonts';

export type ProjectEvaluateMessageCardProps = NonNullableFields<ShifterProjectsJpDataAcfHighlightsContentsMessage> & {
  isDark?: boolean; // requireではなくoptionalにしたいのであえてこの書き方
  isBorderGray?: boolean;
};

const Container = styled.div<Pick<ProjectEvaluateMessageCardProps, 'isDark' | 'isBorderGray'>>`
  padding: 48px 42px 38px 42px;
  border: 1px dashed ${colors.black02};
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ isBorderGray }) =>
    isBorderGray &&
    css`
      border-color: ${colors.gray09};
    `}

  ${({ isDark }) =>
    isDark &&
    css`
      border: none;
      color: #fff;
    `}

  ${mq.onlysp} {
    padding: 32px 28px;
  }
`;

const ProjectEvaluateMessageCardBg = styled.div<Pick<ProjectEvaluateMessageCardProps, 'isDark'>>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: bottom / contain no-repeat url(${bgImage});
  pointer-events: none;
  mix-blend-mode: darken;
  z-index: -1;

  ${({ isDark }) =>
    isDark &&
    css`
      background: top / cover no-repeat url(${bgImageBlack});
    `}
`;

const ProjectEvaluateMessageCardInnerArea = styled.div`
  display: grid;
  width: fit-content;
  ${mq.onlysp} {
    margin-bottom: 20px;
  }
`;

const ProjectEvaluateMessageCardComma = styled.div<Pick<ProjectEvaluateMessageCardProps, 'isDark'>>`
  height: 25px;
  width: 32px;

  &:first-child {
    justify-self: flex-start;
    margin-bottom: 16px;
    background-image: url(${firstCommaIcon});
    background-size: contain;
    ${({ isDark }) =>
      isDark &&
      css`
        background-image: url(${firstCommaIconWhite});
      `}
    ${mq.onlysp} {
      margin-bottom: 10px;
    }
  }
  &:last-child {
    justify-self: flex-end;
    margin-top: 6px;
    background-image: url(${lastCommaIcon});
    ${({ isDark }) =>
      isDark &&
      css`
        background-image: url(${lastCommaIconWhite});
      `}
    background-size: contain;
  }

  ${mq.onlysp} {
    height: 22px;
    width: 28px;
  }
`;

const ProjectEvaluateMessageCardText = styled.div`
  padding: 0px 18px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.48em;
  letter-spacing: 0.1em;

  ${mq.onlysp} {
    padding: 0px 12px;
    font-size: 20px;
  }
`;

const ProjectEvaluateMessageCardName = styled.div`
  font-family: ${fonts.jaGothic};
  padding: 64px 16px 0px;
  font-size: 18px;
  font-weight: 600;
  ${mq.onlysp} {
    margin-top: -6px;
    padding: 0px 14px;
    font-size: 14px;
    line-height: 2.12em;
  }
`;

export const ProjectEvaluateMessageCard: React.FC<ProjectEvaluateMessageCardProps> = props => {
  return (
    <Container {...props}>
      <ProjectEvaluateMessageCardBg isDark={props.isDark} />
      <ProjectEvaluateMessageCardInnerArea>
        <ProjectEvaluateMessageCardComma isDark={props.isDark} />
        <ProjectEvaluateMessageCardText
          dangerouslySetInnerHTML={{ __html: props.text }}
        ></ProjectEvaluateMessageCardText>
        <ProjectEvaluateMessageCardComma isDark={props.isDark} />
      </ProjectEvaluateMessageCardInnerArea>
      <ProjectEvaluateMessageCardName>{props.name}</ProjectEvaluateMessageCardName>
      {/* <TextSet noMargin={true}>{props.role}</TextSet> */}
    </Container>
  );
};
